.widget-anchor {
  margin-left: -6px;
  color: rgb(3, 92, 103);
  text-decoration: none;
  overflow: hidden; 
  text-overflow:  ellipsis;   
  cursor: default;
}
.widget-anchor green {
  color: green;
}
.widget-anchor red {
  color: red;
}
.widget-anchor blue {
  color: #0000ff;
}
.text-rights {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border-top: 2px solid #eee;
  white-space: nowrap;
  display: flex;
}

.text-center {
  text-align: center;
}
.error-container {
  padding: 10px;
  font-size: 14px;
  
}
.common-line {
  border-top: 2px solid #eee;
  white-space: nowrap;
  display: flex;
}
.text-right {
  text-align: center;
}
.mtd {
  border: 1.5px solid rgb(3, 92, 103);
  border-radius: 5px;
  margin-top: 10px;
  font-size: 12px;
  width: 110%;
  cursor: pointer;
  color: rgb(3, 92, 103);
  font-weight: normal;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mtd-calender {
  transform: scale(0.8);
}
.mtd-text {
  margin-top: 7px;
  font-size: 9px;
}
.border-buttom {
  border-bottom: 2px solid #eee;
}
.view-tracking-info {
  border-bottom: 2px solid #eee;
  padding-top: 15px !important;
}
.error-containers {
  padding: 10px;
  font-size: 14px;
  border-top: 2px solid #eee;
  white-space: nowrap;
  display: flex;
}
.eligibility-error-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 0px 10px 0px;
}
.view-all-error {
  background-color: #035c67 !important;
  border-radius: 30%;
  text-transform: none !important;
}
.eligibility-search-btn {
  border: none;
  outline: none !important;
  background: none;
  border-radius: 0%;
  color: rgb(3, 92, 103) !important;
  text-transform: none !important;
}
.view-all-errors {
  margin-top: 20px;
  width: 60%;
  background-color: rgb(3, 92, 103) !important;
  left: 100px;
  text-transform: none !important;
}
.view-my-errors {
  background-color: #035c67 !important;
  border-radius: 30%;
  text-transform: none !important;
}

.chart-container {
  height: 200px;
  width: 200px;
  position: relative;
}

.chart-container canvas {
  position: relative;
  top: 0%;
  left: 90%;
  margin: 0 0 0 -50px;
}
.chart-container-eligibility-error {
  height: 200px;
  width: 200px;
  position: relative;
}
.chart-container-eligibility-error canvas {
  position: absolute;
  top: 0%;
  left: 48%;
  margin: 0 0 0 -50px;
}
.widget-container {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.draggable-container-grid {
  height: auto;
}
.widget-subbtitle {
  margin-top: -20px !important;
}
.widget-title {
  font-size: 20px;
  min-height: 100px;
}
.dashboard-icons {
  color: #035c67;
  font-size: 56px;
}
.sub-title {
  font-size: 13px;
}
.membership-to-error-ratio .member-title {
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
.eligibility-error-ratio {
  display: flex;
  width: 100%;
  padding: 0px 10px;
}

.eligibility-error-doughnut {
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
.eligibility-error-count {
  display: flex;
  width: 5%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.eligibility-error-trends {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error-trends-title {
  font-weight: bold;
}
.mtd-container {
  position: fixed;
}
.mtd-dropdown {
  border: 1px solid rgb(3, 92, 103);
  color: rgb(3, 92, 103);
  padding: 5px;
  border-radius: 5px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 5%;
  cursor: pointer;
  left: 30;
  margin: 0;
  font-weight: normal;
  overflow: hidden;
  font-size: 11px;
  position: absolute;
  background-color: rgb(255, 255, 255); 
  opacity: 1;
  z-index: 999; 
  
}

.mtd-dropdown-li {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  align-items: center;
  position: relative;
}
.mtd-dropdown-li-ob {
  border-bottom: 1px solid rgb(3, 92, 103);
  padding: 5px;
}
.mtd-dropdown-ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
}
.dropdown {
  list-style-type: none;
  border: 1px solid rgb(3, 92, 103);
  color: rgb(3, 92, 103);
  margin: 0;
  padding: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 55px;
  cursor: pointer;
  position: absolute;
  top: 215px;
  left: 200px;
  font-weight: normal;
  overflow: hidden;
  font-size: 11px;
  z-index: 1;
  background-color: white;
  align-items: center;
}
