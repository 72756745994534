.spacer {
  height: 70px;
}

.container {
  padding-top: 20px;
}

.breadcrumbWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-left: -10px;
}
.breadcrumbWrapper.MembershipSearchResults {
  margin-left: -64px;
}
.breadcrumbWrapper.FileTracking {
  margin-left: -64px;
}
.breadcrumbWrapper.EligibilityErrorTrends {
  margin-left: -63px;
}
.breadcrumbWrapper.AdminSecuritySetup {
  margin-left: -54px;
}
.breadcrumbWrapper.FileSearchResults {
  margin-left: -64px;
}
.breadcrumbWrapper.EligibilityErrorsAndWarnings {
  margin-left: -64px;
}
.breadcrumbWrapper.EligibilityErrorsAndWarning {
  margin-left: -64px;
}
.breadcrumbWrapper.ErrorDetails {
  margin-left: -64px;
}
.breadcrumbWrapper.Add_new_subscriber {
  margin-left: -64px;
}

.styledLink {
  margin: 2px;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  color: black;
}

.styledLink:hover {
  text-decoration: underline;
  color: #3f51b5;
}
